import { twMerge } from 'tailwind-merge';
import type { JSXElement } from 'solid-js';
import CardLink from './CardLink';

type Props = {
	href?: string;
	target?: string;
	class?: string;
	children: JSXElement;
};

export default function CardTitle({
	href,
	target,
	class: className,
	children,
	...rest
}: Props): JSXElement {
	return (
		<h2
			class={twMerge(
				'text-lg font-semibold tracking-tight text-zinc-800 dark:text-zinc-100',
				className,
			)}
		>
			{href ? (
				<CardLink
					target={target}
					href={href}
					{...rest}
				>
					{children}
				</CardLink>
			) : (
				<>{children}</>
			)}
		</h2>
	);
}
